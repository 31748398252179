import React, { useState } from "react";
import TeamDropdown from "../user/TeamDropdown";
import BranchDropdown from "../user/BranchDropdown"

const TimesheetSearch = ({ initialConfig, onSearch, onReportGen }) => {
  const [filters, setFilters] = useState({});

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTeamChange = (selectedTeamId) => {
    setFilters({ ...filters, teamId: selectedTeamId });
  };

  const handleBranchChange = (selectedBranchId) => {
    setFilters({ ...filters, branchId: selectedBranchId });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(filters);
  };

  const handleReportGen = (event) => {
    event.preventDefault();
    onReportGen(filters);
  };

  const handleClearFilter = () => {
    setFilters({});
    onSearch({});
  };

  const renderFilter = (filter) => {
    switch (filter.type) {
      case "text":
        return (
          <div key={filter.name} className="col-md-2 mb-3">
            <label
              htmlFor={filter.name}
              className="form-label"
              style={{ fontSize: 10 }}
            >
              {filter.label}:
            </label>
            <input
              style={{ width: 80, maxHeight: 8, fontSize: 10 }}
              type="text"
              className="form-control form-control-sm"
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>
        );
      case "select":
        return (
          <div
            key={filter.name}
            className="col-md-2 mb-3 mt-2"
            style={{ fontSize: 10, width: 90 }}
          >
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            {filter.name === "Team" &&             
            <div >
              {" "}
              <TeamDropdown
                onSelectTeam={handleTeamChange}
                defaultValue={filters.teamId}
                height={31}
              />
            </div> }
            {filter.name === "Branch" &&             
            <div >
              {" "}
              <BranchDropdown
                onSelectBranch={handleBranchChange}
                defaultValue={filters.branchId}
                // height={31}
                settings={{ height: 31,width: 96,  notRequired: true , fontSize:10}} 
              />
            </div> }

          </div>
        );
      case "date":
        return (
          <div
            key={filter.name}
            className="col-md-2 mb-3 mt-2"
            style={{ fontSize: 10 }}
          >
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <input
              style={{ width: 80, maxHeight: 8, fontSize: 10 }}
              type="date"
              className="form-control form-control-sm"
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>
        );
      case "checkbox":
        return (
          <div
            key={filter.name}
            className="col-md-2 mb-3 mt-2"
            style={{ fontSize: 10 }}
          >
            <label className="form-check-label" htmlFor={filter.name}>
              {filter.label}
            </label>
            <input
              type="checkbox"
              className="form-check-input "
              style={{ marginTop: 7, width: 25, height: 25 }}
              id={filter.name}
              name={filter.name}
              checked={filters[filter.name] || false}
              onChange={handleInputChange}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid ">
        <div
          className=" d-flex  "
          style={{ justifyContent: "start", gap: "15px", flexWrap: "nowrap" }}
        >
          <div className="row ">
            {initialConfig.map((filter) => (
              <div
                style={{ paddingRight: "5px", width: 100 }}
                key={filter.name}
              >
                {renderFilter(filter)}
              </div>
            ))}
          </div>
          {/* <div className="row bg-danger " style={{justifyContent:"start"}} >
          {initialConfig.map((filter) => renderFilter(filter))}
          </div> */}
        </div>
        <div className="row mt-3">
          <div className="col-md-2">
            <button
              type="submit"
              className="btn btn-primary w-100"
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Search <i className="fas fa-search"></i>
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
             
              className="btn btn-primary w-100 "
              onClick={handleReportGen}
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Create Report
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleClearFilter}
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Clear <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TimesheetSearch;
