import React, { useEffect, useState } from "react";
import { getBranches } from '../core/helper/coreapicalls';

const BranchDropdown = ({ onSelectBranch, defaultValue, settings = {}}) => {
    const [branchData, setBranchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getBranches();
                setBranchData(data);
            } catch (error) {
                setError(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleBranchChange = (event) => {
        const selectedBranchId = event.target.value;
        const branch = branchData.find((b) => b.branch_id === parseInt(selectedBranchId));
        onSelectBranch(branch); // Pass the selected branch to the parent component
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {branchData.length > 0 && (
                <div style={{minWidth:229}}>
                <select
                
                style={{maxWidth: settings.width || null, maxHeight: settings.height? settings.height : null, fontSize: settings.fontSize? settings.fontSize : 17, }}
       
                 className="form-control input-box" 
                 onChange={handleBranchChange} 
                //defaultValue={defaultValue && typeof defaultValue === 'object' ? defaultValue.branch_id || '' : defaultValue || ''} 
                 value={defaultValue? defaultValue.branch_id : ""} 
                 required={settings.notRequired===true ? null : 'required'} >

                    <option  value="" disabled>Select a branch</option>
                    {branchData.map((branch) => (
                        <option key={branch.branch_id} value={branch.branch_id}>
                            {branch.branch_name}
                        </option>
                    ))}
                </select>
                </div>
            )}
        </>
    );
};

export default BranchDropdown;
