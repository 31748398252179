import React, { useState } from 'react';

const ReportUploadForm = ({ attendance, onUpdate, onClosePopup }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

 

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSizeInBytes = 20 * 1024 * 1024; // 20 MB in bytes

    if (selectedFile && selectedFile.size > maxSizeInBytes) {
      alert('File size must be less than or equal to 20 MB.');
      setFile(null);
      event.target.value = null; // Reset the file input
    } else {
      setFile(selectedFile);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    // Pass file and attendance ID to parent
    setLoading(true);
    onUpdate(attendance.attendance_id, file);
  };

  return (
    <div className="box popup" style={{ maxWidth: 600 }}>
      <div className="popup-content">
        <button 
        aria-hidden="false"
        className="fa fa-times" 
        onClick={onClosePopup}></button>
        <h2>Upload Service Report</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="serviceReportFile" className="form-label">
              Select Service Report
            </label>
            <input
              type="file"
              id="serviceReportFile"
              onChange={handleFileChange}
              className="form-control"
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Uploading...' : 'Upload'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReportUploadForm;
