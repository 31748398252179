import React, { useState, useEffect } from "react";
import ApproveTimesheetForm from "./ApproveTimesheetForm";
import DetailedUserView from "./DetailedUserView";

const TeamLeadControl = (props) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [selectedTimesheetView, setSelectedTimesheetView] = useState(null);

  useEffect(() => {
    setTimesheetsData(props.timesheetsData);
  }, [props.timesheetsData]);

  function decimalToTime(decimalHours) {
    // Split the decimal into hours and minutes
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);

    // Format the time
    var formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes;

    return formattedTime;
  }

  const handleViewClick = (timesheet) => {
    setSelectedTimesheetView(timesheet);
    // console.log("selected timesheet", timesheet)
  };

  const handleEditClick = (timesheet) => {
    setSelectedTimesheet(timesheet);
  };

  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id
          ? editedTimesheet
          : timesheet
      )
    );
    setSelectedTimesheet(null);
  };

  const handleCloseEdit = () => {
    setSelectedTimesheet(null);
  };

  const handleCloseView = () => {
    setSelectedTimesheetView(null);
  };

  return (
    <div className="row">
      <div className="col-xl-3 col-md-12 mt-2 ">
        <div
          className="card border-left-primary shadow-lg "
          style={{ width: 200, backgroundColor: "Indigo" }}
        >
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div
                  className=" font-weight-bold text-white"
                  style={{ fontSize: "13px" }}
                >
                  All Timesheets of your team  
                  Result Count:  {props.totalTimesheetsCount}
                </div>
             
             

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="table-container card border-left-primary shadow m-2"
        style={{ maxHeight: "500px", overflowY: "auto", overflowX: "scroll" }}
      >
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <tr
              style={{
                maxHeight: 30,
                fontSize: 12,
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <th style={{ maxWidth: 30 }}>View</th>
              <th style={{ maxWidth: 30 }}>Edit</th>
              <th style={{ maxWidth: 85 }}>
                Date
                <br />
                y-m-d
              </th>
              <th style={{ maxWidth: 85 }}>
                Eng
                <br />
                Name
              </th>
              <th style={{ maxWidth: 65 }}>
                Start
                <br />
                Time
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                In
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                Out
              </th>
              <th style={{ maxWidth: 65 }}>Return</th>
              <th style={{ maxWidth: 58 }}>
                Normal
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Total
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 52 }}>
                OT
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Exp.
                <br />
                OT
              </th>
              <th style={{ maxWidth: 59 }}>
                OT
                <br />
                Amt
              </th>
              <th style={{ maxWidth: 72 }}>Alw.Amt</th>
              <th style={{ maxWidth: 40 }}>Total</th>
              <th style={{ maxWidth: 50 }}>H.day</th>
              <th style={{ maxWidth: 50 }}>Anch</th>
              <th style={{ maxWidth: 53 }}>OS</th>
              <th style={{ maxWidth: 48 }}>DryD</th>
              <th style={{ maxWidth: 50 }}>
                Team
                <br />
                Lead
              </th>
              <th style={{ maxWidth: 45 }}>Dept Mgr</th>
              <th style={{ maxWidth: 56 }}>
                Final
                <br />
                Status
              </th>
              <th style={{ maxWidth: 65 }}>
                Process
                <br />
                Status
              </th>
              <th style={{ maxWidth: 100 }}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData?.map((timesheet, index) => (
              <tr
                key={timesheet.timesheet_id}
                style={{ fontSize: 13 }}
                className={
                  timesheet.timesheet_teamlead_approve ? "table-success" : ""
                }
              >
                <td>
                  <button
                    type="button"
                    className="btn fa fa-eye"
                    style={{ color: "blue", maxWidth: 30 }}
                    onClick={() => handleViewClick(timesheet)}
                  ></button>
                  
                  {timesheet.timesheet_attendance_id
                    .attendance_sr_upload_location && (
                    <a
                      href={
                        timesheet.timesheet_attendance_id
                          .attendance_sr_upload_location
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                      style={{ color: "blue", maxWidth: 30 }}
                    >
                      <i className="fa-solid fa-file"></i>
                    </a>
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn fa fa-edit"
                    style={{ color: "blue", maxWidth: 30 }}
                    onClick={() => handleEditClick(timesheet)}
                  ></button>
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.timesheet_date}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.emp_name}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_start}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signin}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signout}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_return}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 51,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_normal_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_total_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 52,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_over_time_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_expected_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_overtime_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 64,
                  }}
                >
                  {timesheet.timesheet_allowance_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_Total_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 46,
                  }}
                >
                  {timesheet.timesheet_is_holiday ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                        
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                        
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                        
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                        
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 53,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                        
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                        
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id
                    .attendance_is_dubaidrydock ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                        
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                        
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_dept_manager_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 11, margin: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 11, margin: 2 }}
                    >
                      Accepted
                    </button>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 45,
                  }}
                >
                  {!timesheet.timesheet_final_process_status ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 11, margin: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 11, margin: 2 }}
                    >
                      Completed
                    </button>
                  )}
                </td>

                <td>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedTimesheetView && (
        <DetailedUserView
          timesheet={selectedTimesheetView}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseView}
        />
      )}
      {selectedTimesheet && (
        <ApproveTimesheetForm
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
          onUpdateTimesheet={props.onUpdateTimesheets}
        />
      )}
    </div>
  );
};

export default TeamLeadControl;
